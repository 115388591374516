import React from "react";
import { graphql, PageProps } from "gatsby";

import Hero from "@shared/hero";
import SEO from "@shared/seo";
import { Query, SanityWhatWeDoConnection } from "@graphql-types";
import { useRegionChanged } from "@util/hooks";
import { JobInfo, ValueBlock } from "@global";

interface Data extends Query {
  altRegions: SanityWhatWeDoConnection;
}
interface Props extends PageProps {
  data: Data;
  pageContext: { iban: string };
}

export default function CareerPage({ data, pageContext }: Props) {
  const { nodes } = data.allSanityCareer;
  const { careerHero, seo, valueInfo, jobInfo } = nodes[0];
  const alternateSlugs = data.altRegions.nodes.map((alt) => alt.region);
  useRegionChanged("career", pageContext.iban);

  return (
    <div>
      <SEO seoData={seo} slug="career" alternateRegions={alternateSlugs} heroData={careerHero} />
      <Hero heroData={careerHero} />
      {jobInfo && <JobInfo data={jobInfo} />}
      {valueInfo && <ValueBlock data={valueInfo} />}
    </div>
  );
}

export const query = graphql`
  query CareerPageQuery($iban: String) {
    allSanityCareer(filter: { region: { iban: { eq: $iban } } }) {
      nodes {
        careerHero {
          ...sanityHero
        }
        region {
          ...sanityRegion
        }
        seo {
          ...sanitySeo
        }
        valueInfo {
          ...sanityTeamBlock
        }
        jobInfo {
          _key
          heading {
            _key
            _rawColumnContent
          }
          buttonLink {
            ...sanityLink
          }
        }
      }
    }
    altRegions: allSanityCareer {
      nodes {
        region {
          ...sanityRegion
        }
      }
    }
  }
`;
